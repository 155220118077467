import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Layout from '../components/Layout'
import Logo from '../components/Logo'
import Navigation from '../components/NavigationWithLogo'

// import Navigation from '../components/NavigationWithLogo'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulNeuigkeit
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous, next } = this.props.pageContext

    return (
      <Layout>
        <Logo />
        <Navigation menuText={post.title} />

        <div className="regular-container">
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <section className="section-neues">
            <div className="content">
              <article>
                <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1>
                <h2 style={{ display: 'block' }}>{post.date}</h2>
                <div
                  className="section-content"
                  dangerouslySetInnerHTML={{ __html: post.text.childMarkdownRemark.html }}
                />
              </article>
              <ul
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  listStyle: 'none',
                  padding: 0,
                }}
              >
                {previous && (
                  <li>
                    <h2>
                      <Link to={
                        '/neues/' +
                        previous.slug} rel="prev">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: '← ' + previous.title,
                          }}
                        ></span>
                      </Link>
                    </h2>
                  </li>
                )}

                {next && (
                  <li>
                    <h2>
                      <Link to={
                        '/neues/' +
                        next.slug} rel="next">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: next.title + '  →',
                          }}
                        ></span>
                      </Link>
                    </h2>
                  </li>
                )}
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulNeuigkeit(slug: { eq: $slug }) {
      slug
      text {
        childMarkdownRemark {
          html
        }
      }        
      title
      date(formatString: "dddd, DD. MMMM YYYY", locale: "de")
    }
  }
`
